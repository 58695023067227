.modular-image-grid {
  display: grid;
  min-height: 0px;
  width: 100%;
  min-width: 0px;
  grid-template-columns: max-content max-content max-content;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.btn-white-blue-b, .configurator label, .buttons a, .btn-blue-white, .buttons a:last-child, .btn-orange-white, .btn-orange-blue {
  display: flex;
  width: max-content;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  font-family: roboto, sans-serif;
  font-weight: 700;
  font-size: .75rem;
  border-width: 1px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  height: 39px;
}

.btn-orange-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(249 173 9 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(2 36 91 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(249 173 9 / var(--tw-border-opacity));
}
.btn-orange-blue__square {
  display: flex;
  border-width: 1px;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  --tw-bg-opacity: 1;
  background-color: rgb(249 173 9 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(2 36 91 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(249 173 9 / var(--tw-border-opacity));
  height: 40px;
  width: 40px;
}

@media (min-width: 1200px) {
  .btn-hover-orange-blue-b:hover, .buttons a:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 173 9 / var(--tw-bg-opacity));
  }
  .btn-hover-orange-blue-b:hover, .buttons a:hover {
    --tw-text-opacity: 1;
    color: rgb(2 36 91 / var(--tw-text-opacity));
  }
  .btn-hover-orange-blue-b:hover, .buttons a:hover {
    --tw-border-opacity: 1;
    border-color: rgb(249 173 9 / var(--tw-border-opacity));
  }
}

.btn-orange-white {
  --tw-bg-opacity: 1;
  background-color: rgb(249 173 9 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(249 173 9 / var(--tw-border-opacity));
}

@media (min-width: 1200px) {
  .btn-hover-blue-white-b:hover {
    --tw-border-opacity: 1;
    border-color: rgb(2 36 91 / var(--tw-border-opacity));
  }
  .btn-hover-blue-white-b:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(2 36 91 / var(--tw-bg-opacity));
  }
  .btn-hover-blue-white-b:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .btn-hover-lBlue-white-b:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
  }
  .btn-hover-lBlue-white-b:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 54 114 / var(--tw-bg-opacity));
  }
  .btn-hover-lBlue-white-b:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.btn-blue-white, .buttons a:last-child {
  --tw-bg-opacity: 1;
  background-color: rgb(0 54 114 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(0 54 114 / var(--tw-border-opacity));
}

@media (min-width: 1200px) {
  .btn-hover-white-blue-b:hover {
    --tw-border-opacity: 1;
    border-color: rgb(2 36 91 / var(--tw-border-opacity));
  }
  .btn-hover-white-blue-b:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .btn-hover-white-blue-b:hover {
    --tw-text-opacity: 1;
    color: rgb(2 36 91 / var(--tw-text-opacity));
  }
}

.btn-white-blue-b, .configurator label, .buttons a {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(2 36 91 / var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(0 54 114 / var(--tw-border-opacity));
}

@media (min-width: 1200px) {
  .btn-hover-blue-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 54 114 / var(--tw-bg-opacity));
  }
  .btn-hover-blue-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.configurator input[type=radio] {
  display: none;
}
.configurator input[type=radio]:checked + label {
  --tw-bg-opacity: 1;
  background-color: rgb(2 36 91 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.configurator input[type=radio]:checked + .color-button {
  outline-color: #7f96b2;
}
.configurator input[type=radio]:checked + .image-button {
  outline-color: #7f96b2;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.configurator .color-button {
  display: block;
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
  padding: 0px;
  outline-style: solid;
  outline-width: 1px;
  outline-color: #FFFFFF;
  outline-offset: 4px;
}
.configurator .image-button {
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  display: block;
  height: 7rem;
  width: 7rem;
  padding: 0px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  outline-style: solid;
  outline-width: 1px;
  outline-color: #FFFFFF;
  outline-offset: 4px;
}

.buttons {
  float: right;
  margin-left: -0.25rem;
  margin-top: -0.25rem;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.buttons a {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
@media (min-width: 1200px) {
  .buttons a:hover:last-child {
    --tw-bg-opacity: 1;
    background-color: rgb(249 173 9 / var(--tw-bg-opacity));
  }
  .buttons a:hover:last-child {
    --tw-text-opacity: 1;
    color: rgb(2 36 91 / var(--tw-text-opacity));
  }
  .buttons a:hover:last-child {
    --tw-border-opacity: 1;
    border-color: rgb(249 173 9 / var(--tw-border-opacity));
  }
}

.centerbox {
  display: flex;
  height: 100%;
  justify-content: center;
}

.centered-text {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.form-image {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 8rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}

.configurator-form .freeform-input {
  margin: 0px;
}
.configurator-form .errors {
  margin-top: 0.5rem;
}
.configurator-form .freeform-column + .freeform-column {
  padding-top: 1rem;
}

.pdf th, .pdf td {
  border: 1px solid black;
  padding: 10px;
}
.pdf h1 {
  font-size: 2rem;
}
.pdf tr {
  page-break-inside: avoid;
}

.pdf-overview {
  padding: 4rem;
  padding-top: 2rem;
}
.pdf-overview a {
  display: none;
}